import axios from 'axios'
import { useEffect, useState } from 'react'
import { FlatList, Modal, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { formatDate } from '../../Helpers/convertDate'
import { BULK_REQUESTED_SONGS, BULK_SONG_REQUEST, colors } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'
import { PrimaryButton, SecondaryButton } from '../Buttons/Button'
import { RequestedSongsDetails } from './RequestedSongsDetails'

export const RequestedSongs = ({
	modalVisible,
	cancelCallback
}) => {

	const spotifyLabel = 'Spotify'
	const youtubeLabel = 'Youtube'
	const csvLabel = 'CSV File'
	const appleLabel = 'Apple Music'

	const { getTokens } = useTokens()
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700 && height > 1000)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(false)
	const [requestedSongs, setRequestedSongs] = useState([])
	const [showRequestedSongsDetailsModal, setShowRequestedSongsDetailsModal] = useState(false)
	const [songList, setSongList] = useState([])

	const handleResize = () => {
		setIsWideScreen(width > 700 && height > 1000)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	useEffect(() => {
		getAllBulkRequests()
	}, [])

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '70%'
		} else {
			return '90%'
		}
	}

	const modalStyle = StyleSheet.create({
		centeredView: {
			flex: 1,
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: colors.secondary,
			borderRadius: 20,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5,
			width: getModalWidth(),
			height: isWideScreen ? '90%' : '90%',
			marginTop: 20,
			alignItems: 'center'
		},
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : (isSmallScreen ? 12 : 18),
			marginLeft: '5%',
		},
		sublabel: {
			color: 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: '5%',
			marginBottom: 10
		},
		innerLabel: {
			color: 'white',
			padding: '2%',
			fontSize: isWideScreen ? 16 : (isSmallScreen ? 10 : 12),
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 12,
			marginLeft: '10%',
		},
		trackListScrollView: {
			flexGrow: 1,
			borderColor: 'gray',
			borderRadius: 5,
			borderWidth: 2,
			width: '90%',
			minHeight: '30%',
			marginHorizontal: '3%',
			maxHeight: isWideScreen ? '70%' : '75%'
		}
	})

	const getAllBulkRequests = async () => {
		const { access } = await getTokens()
		const { data } = axios
			.get(BULK_SONG_REQUEST, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(async response => {
				let playlists = []
				response.data.map(item => {
					playlists.push(item)
				})
				setRequestedSongs(playlists)
			})
			.catch(err => {
				alert({ error: true, message: 'Noget gik galt' })
				console.log('createBulkRequest: ' + err)
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
			})

		return data
	}

	const getBulkRequestById = async (bulkId) => {
		const { access } = await getTokens()
		const { data } = axios
			.get(BULK_REQUESTED_SONGS + bulkId, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(async response => {
				setSongList(response.data)
				setShowRequestedSongsDetailsModal(true)
			})
			.catch(err => {
				alert({ error: true, message: 'Noget gik galt' })
				console.log('getBulkRequestById: ' + err)
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
			})

		return data
	}

	const getSourcePlatform = (sourceId) => {
		let result = ''
		switch (sourceId) {
			case 0:
				result = spotifyLabel
				break
			case 1:
				result = youtubeLabel
				break
			case 2:
				result = appleLabel
				break
			case 3:
				result = csvLabel
				break
		}

		return result
	}

	const getFormattedDate = (date) => {
		return formatDate(date)
	}

	const handleViewDetails = (bulkId) => {
		getBulkRequestById(bulkId)
	}

	const cancelCallbackRequestedSongsDetails = () => {
		setShowRequestedSongsDetailsModal(false)
	}

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				cancelCallback()
			}}
		>
			<View style={modalStyle.centeredView}>
				<View style={modalStyle.modalView}>
					<View style={{ width: '100%', alignItems: 'flex-start'}}>
						<Text style={[modalStyle.sublabel, { marginTop: '5%' }]}>Anmodede Numre</Text>
					</View>
					<ScrollView style={modalStyle.trackListScrollView}>
						<View style={{
								flexDirection: 'row',
								justifyContent: 'flex-start',
								alignItems: 'center',
								marginRight: 0,
								width: '100%',
								borderColor: 'gray',
								borderBottomWidth: isWideScreen ? 3 : 2
							}}
						>
							<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '22%' : (isSmallScreen ? '22%' : '22%') }]}>{isSmallScreen ? 'Kilde platform' : 'Kilde platform'}</Text>
							<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '35%' : (isSmallScreen ? '25%' : '27%') }]}>Spilleliste Navn</Text>
							<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '22%' : (isSmallScreen ? '22%': '22%') }]}>Dato Forespørgsel</Text>
							<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '21%': '15%') }]}>Status</Text>
							<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '10%' : (isSmallScreen ? '10%': '15%') }]}>{'      '}</Text>
						</View>
						<FlatList
							data={requestedSongs}
							renderItem={song =>
								<View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }} >
									<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '22%' : (isSmallScreen ? '22%' : '22%') }]}>{getSourcePlatform(song.item.source_external_app)}</Text>
									<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '35%' : (isSmallScreen ? '25%' : '27%') }]}>{song.item.playlist_name}</Text>
									<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '22%' : (isSmallScreen ? '22%': '21%') }]}>{getFormattedDate(song.item.creation_date)}</Text>
									<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '21%': '15%') }]}>{song.item.status_description}</Text>
									<PrimaryButton
										title='Vis'
										onPress={() => handleViewDetails(song.item.id)}
										style={{ width: isWideScreen ? '10%' : (isSmallScreen ? '10%': '15%'), minHeight: isWideScreen ? 35 : 30, height: isWideScreen ? 35 : 30 }}
									/>
								</View>
							}
							ListEmptyComponent={
								<Text style={{ color: 'white' }}>Ingen data...</Text>
							}
							keyExtractor={song => song.id}
						/>
					</ScrollView>
					<View style={{ 
							width: '90%', 
							marginHorizontal: '5%', 
							flexDirection: 'row', 
							justifyContent: 'center', 
							marginTop: 10 
						}}
					>
						<SecondaryButton
							title='Luk'
							onPress={() => cancelCallback()}
							style={{ width: '60%', minHeight: isWideScreen ? 20 : 15 }}
						/>
					</View>
				</View>
			</View>

			{showRequestedSongsDetailsModal && (
				<RequestedSongsDetails
					songList={songList}
					cancelCallback={cancelCallbackRequestedSongsDetails}
				/>
			)}
		</Modal>
	)
}
