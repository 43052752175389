import { useState, useEffect } from 'react'
import { Pressable, View, useWindowDimensions } from 'react-native'
import useSound from '../../../Hooks/useSound'
import { TrackMenu } from '../../TracksScreen/components/TrackMenu'
import TrackCard from './TrackCard'
import usePlaybackStatus from '../../../Hooks/usePlaybackStatus'
import { createPermissionsPlugin } from '@expo/config-plugins/build/ios/Permissions'

export default function MainTrackCard({
	track,
	userPlaylists,
	updateUserPlaylists,
	openNotifModal,
	closeNotifModal,
	item,
	index,
	tracks
}) {
	const { width, height } = useWindowDimensions()
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const { setPlaybackStatus } = usePlaybackStatus()

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const { addTracks, playTrack, playSoloTrack, currentPlayingTrack } = useSound()
	const [menuVisible, setMenuVisible] = useState(false)
	
	const handlePressTrack = async () => {
		try {
			console.log('MainTrackCard - handlePressTrack')
			console.log('MainTrackCard - tracks', tracks)
			console.log('MainTrackCard - track', track)
			console.log('MainTrackCard - index', index)
			await addTracks(tracks).then(async result1 => {
				await playTrack(track, index, true).then(result2 => {
					setPlaybackStatus({
						isPlaying: true
					})
				})
			})
		} catch (ex) {
			console.log('ERROR: ' + ex)
		}
	}

	return (
		<View
			key={'main' + track.id}
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				height: 165,
				margin: isWideScreen ? 10 : 5
			}}
		>
			<View
				style={{
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					width: '100%',
					height: '100%'
				}}
			>
				<Pressable
					onPress={() => {
						handlePressTrack()
					}}
					key={track.id}
				>
					<TrackCard
						image={track.album_photo}
						title={track.song_title}
						secondTitle={track.artist_name}
						hasTrackMenuBtn={true}
						number={index + 1}
					/>
				</Pressable>
			</View>

			<View
				style={{ marginTop: isSmallScreen ? -100 : -120, marginLeft: isSmallScreen ? 60 : 80 }}
				key={'submaintrack' + track.id}
			>
				<TrackMenu
					key={'trackmenucaller' + track.id}
					track={track}
					menuVisible={menuVisible}
					setMenuVisible={setMenuVisible}
					isTracksRemovable={false}
					userPlaylists={userPlaylists}
					updateUserPlaylists={updateUserPlaylists}
					openNotifModal={openNotifModal}
					closeNotifModal={closeNotifModal}
					item={item}
					currentPlayingTrack={currentPlayingTrack}
				/>
			</View>
		</View>
	)
}
