import { StyleSheet } from 'react-native'
import { colors } from '../../../Helpers/variables'

export const trackStyles = StyleSheet.create({
	scrollView: {
		paddingTop: 20
	},
	trackList: {
		flex: 2,
		marginLeft: 20
	},
	trackListHeader: {
		fontSize: 12,
		color: 'white',
		paddingBottom: 5,
	},
	noData: {
		color: colors.accent,
		marginTop: 30,
		fontSize: 20
	},
	trackRow: {
		marginBottom: 20,
		flex: 1,
		alignSelf: 'stretch',
		flexDirection: 'row',
		alignItems: 'center'
	},
	leftContainer: {
		// flex: 1,
		// alignSelf: 'stretch',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	albumContainer: {
		flex: 1,
		alignSelf: 'stretch',
	},
	songContainer: {
		flex: 2,
		alignSelf: 'stretch'
	},
	albumTitle: {
		color: 'white',
		fontSize: 12,
		width: '70%'
	},
	menuView: {
		backgroundColor: colors.secondary,
		width: 200,
		overflow: 'visible'
	},
	menuTextView: {
		color: 'white',
		fontSize: 14
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
		elevation: 5,
		minWidth: 200,
		justifyContent: 'center',
		height: 'auto',
		maxHeight: 600
	}
})
