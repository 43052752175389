import { Ionicons } from '@expo/vector-icons'
import { View, TouchableHighlight, useWindowDimensions } from 'react-native'
import { useEffect, useState } from 'react'

export const MusicTherapistsMenu = ({ handleTherapistMenu }) => {
	const {width} = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	
	const handleResize = () => {
		setIsWideScreen(width > 700)
	}
	
	useEffect(() => {
		handleResize()
	}, [width])

	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<TouchableHighlight
				onPress={() => {
					handleTherapistMenu()
				}}
			>
				<View style={{ flexDirection: 'row' }}>
					<Ionicons name="people-outline" style={{ marginLeft: 15 }} size={isWideScreen ? 34 : 24} color="white" />
				</View>
			</TouchableHighlight>
		</View>
	)
}
