import React, { useRef, useState } from 'react'
import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'
import convertTimeToMins, { convertTimeWithSecs } from '../../Helpers/timeConverter'
import useAudio from '../../Hooks/useAudio'
import TrackPlayer, { State } from 'react-native-track-player'

export const TimerContext = React.createContext()

export const TimerProvider = ({ children }) => {
	const timerId = useRef(null)
	const [timerProperties, setTimerProperties] = useState({
		timerMinutes: 60,
		timerCountSeconds: 0,
		timerMinutesOriginal: 60,
		isLoaded: false,
		isTimerChecked: false,
		timerId: undefined,
		isTimerOnCountdown: false,
		endDate: undefined,
		countdownTimer: '01:00:00'
	})

	const secondsToHMS = secs => {
		const z = n => {
			return (n < 10 ? '0' : '') + n
		}
		var sign = secs < 0 ? '-' : ''
		secs = Math.abs(secs)
		let hr = secs / 3600000
		if (hr < 60) hr = 0
		else hr = parseInt(hr / 60)

		return sign + z(hr | 0) + ':' + z(((secs % 3600) / 60) | 0) + ':' + z(parseInt(secs % 60))
	}

	const mediaPlayerTimerInterval = async () => {
		let isTimerChecked = timerProperties.isTimerChecked
		if (isTimerChecked) {
			let isTimerOnCountdown = timerProperties.isTimerOnCountdown
			let timerMinutes = timerProperties.timerMinutes
			let timerCountSeconds = timerProperties.timerCountSeconds
			let currentDate = new Date()
			let endDate = isTimerOnCountdown ? timerProperties.endDate : convertTimeToMins(timerMinutes)
			let seconds = (endDate - currentDate) / 1000
			let countdownTimer = secondsToHMS(seconds)
			// let currentDateTime = currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds()
			if (seconds <= 0) {
				isTimerChecked = false
				isTimerOnCountdown = false
				//pause if currently playing
				if((await TrackPlayer.getPlaybackState()).state === State.Playing) 
					TrackPlayer.pause()

				timerMinutes = 0
				timerCountSeconds = 60
				countdownTimer = '01:00:00'
			} else {
				isTimerOnCountdown = true
				timerMinutes = timerProperties.timerMinutes
				timerCountSeconds = timerCountSeconds - 1
			}
			setTimerProperties({
				timerMinutes: timerMinutes === 0 ? timerProperties.timerMinutesOriginal : timerMinutes,
				timerCountSeconds: timerCountSeconds,
				timerMinutesOriginal: timerProperties.timerMinutesOriginal,
				isLoaded: timerProperties.isLoaded,
				isTimerChecked: isTimerChecked,
				timerId: timerId.current,
				isTimerOnCountdown: isTimerOnCountdown,
				endDate: endDate,
				countdownTimer: countdownTimer
			})
		}
	}

	/*
	 * function handleTimerChange()
	 *
	 * @description: Handles the timer change including the countdown
	 */
	const handleTimerChange = async (isTimerChecked = undefined, timerMinutes = undefined) => {
		try {
			if (!timerMinutes)
				timerMinutes = !isTimerChecked ? timerProperties.timerMinutesOriginal : timerProperties.timerMinutes
			isTimerChecked = isTimerChecked === undefined ? !timerProperties.isTimerChecked : isTimerChecked
			let isTimerOnCountdown = false
			if (isTimerChecked && !timerProperties.isTimerOnCountdown) {
				timerMinutes = parseInt(timerMinutes) * 1000 * 60
				let timerCountSeconds = 60
				let endDate = convertTimeToMins(timerMinutes)
				setTimerProperties({
					timerMinutes: timerMinutes === 0 ? timerProperties.timerMinutesOriginal : timerMinutes,
					timerCountSeconds: timerCountSeconds,
					timerMinutesOriginal: timerProperties.timerMinutesOriginal,
					isLoaded: timerProperties.isLoaded,
					isTimerChecked: isTimerChecked,
					timerId: timerId.current,
					isTimerOnCountdown: isTimerOnCountdown,
					endDate: endDate,
					countdownTimer: timerProperties.countdownTimer
				})
			} else {
				setTimerProperties({
					timerMinutes: timerMinutes,
					timerCountSeconds: 0,
					timerMinutesOriginal: timerProperties.timerMinutesOriginal,
					isLoaded: timerProperties.isLoaded,
					isTimerChecked: isTimerChecked,
					timerId: timerId.current,
					isTimerOnCountdown: isTimerOnCountdown,
					endDate: timerProperties.endDate,
					countdownTimer: convertTimeWithSecs(timerMinutes, 0)
				})
			}
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	const clearTimeoutId = () => {
		setTimerProperties({
			timerMinutes: 60,
			timerCountSeconds: 0,
			timerMinutesOriginal: 60,
			isLoaded: false,
			isTimerChecked: false,
			timerId: undefined,
			isTimerOnCountdown: false
		})
	}

	// useEffect(() => {
	// 	if (timerProperties.isLoaded)
	// 		if (timerProperties.timerMinutes !== timerProperties.timerMinutesOriginal)
	// 			handleTimerChange(false)
	// 		else handleTimerChange()
	// }, [timerProperties])

	return (
		<TimerContext.Provider
			value={{
				handleTimerChange,
				timerProperties,
				setTimerProperties,
				clearTimeoutId,
				mediaPlayerTimerInterval
			}}
		>
			{children}
		</TimerContext.Provider>
	)
}
