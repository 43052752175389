import { useState, useEffect } from 'react'
import { Text, View, useWindowDimensions, FlatList } from 'react-native'
import { arrayListSortByKey } from '../../Helpers/sortArray'
import { MM_GENRE_URL, MM_YEAR_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import { CheckboxTicker } from '../Forms/CheckboxTicker'
import { Dropdown } from '../Forms/Dropdown'

export const SearchFilterCategory = ({ handleChangeFilters }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isPortrait, setIsPortrait] = useState(width < 700 && height > width)
	const [isLandscape, setIsLandscape] = useState(width > height)

	const yearLabel = 'År'
	const genreLabel = 'Genre'
	const allLabel = 'Alle'
	const playlistLabel = 'Spillelister'
	const accompanimentLabel = 'Akkompagnement til fællessang'
	const composerLabel = 'Komponist'

	const [categories, setCategories] = useState([
		{
			name: allLabel,
			isChecked: false,
			columnNumber: '1'
		},
		{
			name: 'Sange',
			isChecked: true,
			icon: 'musical-notes-outline',
			columnNumber: '2'
		},
		{
			name: 'Kunstnere',
			isChecked: false,
			icon: 'person-outline',
			columnNumber: '1'
		},
		{
			name: 'Albums',
			isChecked: false,
			icon: 'albums-outline',
			columnNumber: '1'
		},
		{
			name: playlistLabel,
			isChecked: false,
			icon: 'library-sharp',
			columnNumber: '2'
		},
		// {
		// 	name: categoryLabel,
		// 	isChecked: false,
		// 	icon: 'library-sharp',
		// 	columnNumber: '3'
		// },
		{
			name: yearLabel,
			isChecked: false,
			icon: 'calendar-outline',
			columnNumber: '1'
		},
		{
			name: genreLabel,
			isChecked: false,
			icon: 'albums-outline',
			columnNumber: '1'
		},
		{
			name: accompanimentLabel,
			isChecked: false,
			icon: 'albums-outline',
			columnNumber: '2'
		},
		{
			name: composerLabel,
			isChecked: false,
			icon: 'book-sharp',
			columnNumber: '2'
		},
	])

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsPortrait(width < 700 && height > width)
		setIsLandscape(width > height)
	}

	const [yearStartRange, setYearStartRange] = useState(allLabel)
	const [yearEndRange, setYearEndRange] = useState(allLabel)

	const [years, setYears] = useState([])

	const [genre, setGenre] = useState(undefined)
	const [genres, setGenres] = useState([])

	const api = useAxios()

	const getYears = async () => {
		try {
			let yearsTemp = []
			const { data } = await api.get(MM_YEAR_URL)
			let newData = data.Year.sort(function (a, b) {
				return a - b
			})
			newData.map(item => {
				if (item.length > 3)
					yearsTemp.push({
						value: item,
						label: item
					})
			})
			yearsTemp = arrayListSortByKey(yearsTemp, 'label')

			yearsTemp.splice(0, 0, {
				value: allLabel,
				label: allLabel
			})
			if (yearsTemp.length > 0) setYearStartRange(yearsTemp[0].value)
			setYears(yearsTemp)
		} catch (e) {
			console.log(e)
		}
	}

	const getGenres = async () => {
		try {
			let genresTemp = []
			const { data } = await api.get(MM_GENRE_URL)
			data.Genre.map(item => {
				genresTemp.push({
					value: item,
					label: item
				})
			})
			genresTemp = arrayListSortByKey(genresTemp, 'label')
			genresTemp.splice(0, 0, {
				value: allLabel,
				label: allLabel
			})
			if (genresTemp.length > 0) setGenre(genresTemp[0].value)
			setGenres(genresTemp)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getYears()
		getGenres()
	}, [])

	useEffect(() => {
		handleChangeFilters(categories.filter(x => x.isChecked === true))
	}, [categories])

	const onChangeCategory = objItem => {
		let categoriesTemp = [...categories]
		if (objItem.name === allLabel) {
			let temp = []
			categoriesTemp.map((item, index) => {
				if (item.name === allLabel) {
					item.isChecked = !objItem.isChecked
					if (!item.isChecked) {
						setYearStartRange(allLabel)
						setYearEndRange(allLabel)
						setGenre(allLabel)
					} else {
						item.genre = allLabel
						item.yearRange = allLabel
						setYearStartRange(allLabel)
					}
				} else {
					item.isChecked = objItem.isChecked
				}
				if (item.isChecked) {
					if (years.length > 1 && item.name === yearLabel) {
						item.yearRange = years[1].value + '-' + years[years.length - 1].value
					}
					if (genres.length > 1 && item.name === genreLabel) {
						item.genre = allLabel
					}
				}
				temp.push(item)
			})
			setCategories(temp)
		} else {
			let categoryIndex = categoriesTemp.findIndex(x => x.name === objItem.name)
			let categoryItem = categoriesTemp[categoryIndex]
			categoryItem.isChecked = !objItem.isChecked
			if (years.length > 1 && objItem.name === yearLabel) {
				categoryItem.yearRange = years[1].value + '-' + years[years.length - 1].value
			}
			if (genres.length > 1 && objItem.name === genreLabel) {
				categoryItem.genre = allLabel
			}
			categoriesTemp[categoryIndex] = categoryItem
			if (
				categoriesTemp.filter(x => x.isChecked === true && x.name !== allLabel).length ===
				categories.filter(x => x.name !== allLabel).length
			) {
				let categoryIndex = categoriesTemp.findIndex(x => x.name === allLabel)
				let categoryItem = categoriesTemp[categoryIndex]
				categoryItem.isChecked = true
			} else {
				let categoryIndex = categoriesTemp.findIndex(x => x.name === allLabel)
				let categoryItem = categoriesTemp[categoryIndex]
				categoryItem.isChecked = false
			}

			setCategories(categoriesTemp)
		}
	}

	const onChangeSelectYearStartRange = selected => {
		let categoriesTemp = [...categories]
		let categoryIndex = categoriesTemp.findIndex(x => x.name === yearLabel)
		let categoryItem = categoriesTemp[categoryIndex]
		if (selected === allLabel && years.length > 1) {
			categoryItem.yearRange = years[1].value + '-' + years[years.length - 1].value
		} else {
			categoryItem.yearRange =
				selected + '-' + (yearEndRange === allLabel ? years[years.length - 1].value : years[0].value)
		}
		categoriesTemp[categoryIndex] = categoryItem
		setCategories(categoriesTemp)
		setYearStartRange(selected)
		setYearEndRange(years[0].value)
	}

	const onChangeSelectYearEndRange = selected => {
		let categoriesTemp = [...categories]
		let categoryIndex = categoriesTemp.findIndex(x => x.name === yearLabel)
		let categoryItem = categoriesTemp[categoryIndex]
		if (selected === allLabel && years.length > 1) {
			categoryItem.yearRange = yearStartRange + '-' + years[years.length - 1].value
		} else {
			categoryItem.yearRange = yearStartRange + '-' + selected
		}
		categoriesTemp[categoryIndex] = categoryItem
		setCategories(categoriesTemp)
		setYearEndRange(selected)
	}

	const onChangeSelectGenre = selected => {
		let categoriesTemp = [...categories]
		let categoryIndex = categoriesTemp.findIndex(x => x.name === genreLabel)
		let categoryItem = categoriesTemp[categoryIndex]
		categoryItem.genre = selected
		categoriesTemp[categoryIndex] = categoryItem
		setCategories(categoriesTemp)
		setGenre(selected)
	}

	const YearChecklist = ({ item }) => {
		return (
			<View style={{ flexDirection: 'row', width: 170 }}>
				<View>
					<CheckboxTicker
						isChecked={item.isChecked}
						label={item.name}
						handleCheckboxClick={onChangeCategory}
						item={item}
						style={{ fontSize: 15 }}
					/>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: isWideScreen ? 0 : 5, marginLeft: isPortrait ? 20 : 0 }}>
					<Dropdown
						onValueChange={onChangeSelectYearStartRange}
						value={yearStartRange}
						items={years}
						width={70}
						key={'yearsstartid'}
						isDisabled={item.isChecked ? false : true}
					/>
					{yearStartRange !== allLabel &&
						years.filter(
							x =>
								x.value >= yearStartRange &&
								(years.filter(x => x.value >= yearStartRange).length === 2 ? x.value !== allLabel : x.value === x.value)
						).length > 1 && (
							<Dropdown
								onValueChange={onChangeSelectYearEndRange}
								value={yearEndRange}
								items={years.filter(
									x =>
										x.value >= yearStartRange &&
										(years.filter(x => x.value >= yearStartRange).length === 2
											? x.value !== allLabel
											: x.value === x.value)
								)}
								width={70}
								key={'yearsendid'}
							/>
						)}
				</View>
			</View>
		)
	}

	const GenreChecklist = ({ item }) => {
		return (
			<View style={{ marginLeft: isWideScreen || isLandscape ? 30 : 0, flexDirection: 'row', marginTop: isPortrait ? 15 : 0, marginBottom: isPortrait ? 5 : 0 }}>
				<View>
					<CheckboxTicker
						isChecked={item.isChecked}
						label={item.name}
						handleCheckboxClick={onChangeCategory}
						item={item}
						style={{ fontSize: 15, marginRight: isPortrait ? 4 : 0 }}
					/>
				</View>
				<View>
					<Dropdown
						onValueChange={onChangeSelectGenre}
						value={genre}
						items={genres}
						width={isWideScreen || isLandscape ? 210 : 180}
						key={'genresid'}
						isDisabled={item.isChecked ? false : true}
					/>
				</View>
			</View>
		)
	}

	const FilterChecklist = ({ item }) => {
		return (
			<CheckboxTicker
				isChecked={item.isChecked}
				label={item.name}
				handleCheckboxClick={onChangeCategory}
				item={item}
				style={{ fontSize: 15, width: '100%' }}
			/>
		)
	}

	const FilterCategoryCheckboxList = () => {
		return (
			<View style={{ alignItems: isWideScreen || isLandscape ? 'center' : 'flex-start' }}>
				<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
					{isWideScreen || isLandscape ? (
						<FlatList
							horizontal
							data={categories.filter(x => x.name !== genreLabel && x.name !== yearLabel)}
							renderItem={({ item }) =>
								<View style={{ marginRight: isWideScreen || isLandscape ? 10 : 0 }} >
									<FilterChecklist item={item} />
								</View>
							}
							ListEmptyComponent={
								<Text style={{ color: 'white' }}>Ingen data...</Text>
							}
							keyExtractor={item => item.name}
						/>
					) : (
						<FlatList
							data={categories.filter(x => x.name !== genreLabel && x.name !== yearLabel && x.columnNumber === '1')}
							renderItem={({ item }) =>
								<View style={{ marginRight: isWideScreen || isLandscape ? 30 : 0 }} >
									<FilterChecklist item={item} />
								</View>
							}
							ListEmptyComponent={
								<Text style={{ color: 'white' }}>Ingen data...</Text>
							}
							keyExtractor={item => item.name}
						/>
					)}
					{isPortrait ? (
						<FlatList
							data={categories.filter(x => x.name !== yearLabel && x.columnNumber === '2')}
							renderItem={({ item }) =>
								<View>
									<FilterChecklist item={item} />
								</View>
							}
							ListEmptyComponent={
								<Text style={{ color: 'white' }}>Ingen data...</Text>
							}
							keyExtractor={item => item.name}
						/>
					) : <></>}
					{/* {isPortrait ? (
						<FlatList
							data={categories.filter(x => x.name !== yearLabel && x.columnNumber === '3')}
							renderItem={({ item }) =>
								<View>
									<FilterChecklist item={item} />
								</View>
							}
							ListEmptyComponent={
								<Text style={{ color: 'white' }}>Ingen data...</Text>
							}
							keyExtractor={item => item.name}
						/>
					) : <></>} */}
				</View>
				<View style={{ flexDirection: 'row', marginLeft: isWideScreen || isLandscape ? -20 : 0 }}>
					<YearChecklist item={categories.find(x => x.name === yearLabel)} />
					{isWideScreen || isLandscape ? (
						<GenreChecklist item={categories.find(x => x.name === genreLabel)} />
					) : <></>}
				</View>
				{isPortrait ? (
					<View style={{ flexDirection: 'row' }}>
						<GenreChecklist item={categories.find(x => x.name === genreLabel)} />
					</View>
				) : <></>}
			</View>
		)
	}

	return (
		<View>
			<FilterCategoryCheckboxList />
		</View>
	)
}
