import { useEffect, useState } from 'react'
import { Modal, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { TourButton } from '../../Components/Buttons/Button'

export const TourModal = ({
	title = 'Guidet tur igennem appen',
	description = 'Velkommen til Musicmind',
	visible,
	skipTour,
	startTour,
	setTourModal
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			elevation: 5,
			zIndex: 999
		},
		modalView: {
			margin: isWideScreen ? 20 : 10,
			backgroundColor: 'white',
			borderRadius: 20,
			padding: isWideScreen ? 35 : 25,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5,
			zIndex: 999,
			minWidth: isWideScreen ? '40%' : '80%',
			alignItems: 'center',
			justifyContent: 'center',
			height: isWideScreen ? '40%' : '80%',
			width: isWideScreen ? '40%' : '80%'
		}
	})

	return (
		<Modal
			animationType="slide"
			visible={visible}
			transparent={true}
			onRequestClose={() => {
				skipTour()
			}}
		>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<Text style={{ marginBottom: isWideScreen ? 20 : 10, color: 'black', fontSize: isWideScreen ? 30 : (isSmallScreen ? 20 : 24) }}>{title}</Text>
					<Text style={{ marginBottom: isWideScreen ? 20 : 10, color: 'black', fontSize: isWideScreen ? 30 : (isSmallScreen ? 20 : 24) }}>{description}</Text>
					<View style={{ flexDirection: 'row' }}>
						<TourButton
							title={'Spring guiden over'}
							onPress={() => {
								setTourModal()
								skipTour(true)
							}}
							style={{ width: isWideScreen ? 180 : (isSmallScreen ? 120 : 150), margin: isWideScreen ? 10 : 5, color: 'black' }}
						/>
						<TourButton
							title={'Start guiden'}
							onPress={() => {
								setTourModal()
								startTour()
							}}
							style={{ width: isWideScreen ? 180 : (isSmallScreen ? 120 : 150), margin: isWideScreen ? 10 : 5, color: 'black' }}
						/>
					</View>
				</View>
			</View>
		</Modal>
	)
}
