/* -------------------------------- libraries ------------------------------- */
import { useEffect, useState } from 'react'
import { View } from 'react-native'

/* ------------------------------- components ------------------------------- */
import Loader from '../../Components/Loader/Loader'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import RefreshButton from '../../Components/RefreshButton'

/* --------------------------------- helpers -------------------------------- */
import { captureException } from '../../Helpers/sentryLog'
import { playlistSortByName, tracklistSortByPosition } from '../../Helpers/sortArray'
import { colors, PLAYLIST_TRACKS_EDIT_URL, PLAYLIST_TRACKS_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'

/* ---------------------------------- hooks --------------------------------- */
import useAxios from '../../Hooks/useAxios'

/* ---------------------------- local components ---------------------------- */
import useSound from '../../Hooks/useSound'
import TrackList from './components/TrackList'
import { Alert } from 'react-native';

export default function TracksScreen({ route, navigation, ...props }) {
	console.log('TracksScreen')
	const api = useAxios()

	const [trackListState, setTrackListState] = useState([])
	const [userPlaylists, setUserPlaylists] = useState([])
	const [edit, setEdit] = useState(false)
	const {
		playlistId,
		photo,
		title,
		isTracksRemovable,
		isTrackPositionChangeable,
		hasCopyPlaylistBtn,
		musicTherapistName,
		musicTherapistId,
		copyMyPlaylistLabel,
		description
	} = route.params ? route.params : global.routeParams

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(true)
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const { setTrackList, trackList, setCurrentPlayingTrackIndex, currentPlayingTrackIndex, currentPlayingTrack,
		addTracksToPlayer
	 } = useSound()

	const handleSetTrackList = (newTracks, isDeleting = false) => {
		if (newTracks.length > 0 && trackList.length > 0) {
			if (newTracks[0].playlist === trackList[0]?.playlist) {
				if (currentPlayingTrack) {
					let playlistTrackId = trackList[currentPlayingTrackIndex].id
					let newCurrentTrackIndex = newTracks.findIndex(x => x.id === playlistTrackId)
					console.log(JSON.stringify(newCurrentTrackIndex))
					setCurrentPlayingTrackIndex(newCurrentTrackIndex)
					addTracksToPlayer(newTracks)
					setTrackList(newTracks)
					setTrackListState(newTracks)
				}
			}
		}
		
		if (isDeleting) {
			console.log('isDeleting')
			addTracksToPlayer(newTracks)
			setTrackList(newTracks)
			setTrackListState(newTracks)
		}
	}

	const getDataFromApi = () => {
		setLoading(true)
		addTracksToPlayer([])
		setTrackListState([])
	
		Promise.all([USER_PLAYLIST_URL, PLAYLIST_TRACKS_URL + playlistId].map(endpoint => api.get(endpoint)))
			.then(([{ data: user }, { data: trackLists }]) => {
				user = playlistSortByName(user)
				setUserPlaylists(user)
				// addDataToUserPlaylistsTable(user)
	
				if (trackLists.length > 0) trackLists[0].refreshUrl = PLAYLIST_TRACKS_URL + playlistId
	
				if (!edit) {
					// Filter tracks
					const nonNullTracks = trackLists.filter(track => track.track.track_file !== null)
					const nullTracks = trackLists.filter(track => track.track.track_file === null)
	
					// Sort the non-null tracks
					const sortedTracks = tracklistSortByPosition(nonNullTracks)
	
					// Combine sorted non-null tracks with null tracks
					const finalTrackList = nullTracks.length > 0 ? [...sortedTracks, ...nullTracks] : sortedTracks
	
					setTrackListState(finalTrackList)
				}
	
				// addDataToTracksTable(trackLists)
			})
			.catch(err => {
				console.log(err.message)
				setError(true)
				captureException(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id, name, description, visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				getDataFromApi()
			} catch (error) {
				captureException(error)
				setError(true)
				setLoading(false)
			}
		}

		boot()
	}, [route.params ? route.params : global.routeParams, shouldRefresh])

	const saveTracksPosition = async (newTracks) => {
		try {
			for (const [index, trackItem] of newTracks.entries()) {
				let position = index
				trackItem.position = position
				let track = trackItem.track.id
				let playlist = trackItem.playlist
	
				try {
					const response = await api.put(PLAYLIST_TRACKS_EDIT_URL + trackItem.id, { position, track, playlist })
	
					if (response.status === 200) {
						console.log('Success', `Track: ${trackItem.track.song_title}\nPosition: ${position}\nUpdated successfully`)
					} else {
						console.log(`Failed: ${trackItem.track.song_title}\nPosition: ${position}\nFailed to update`)
						Alert.alert('Failure', `Track: ${trackItem.track.song_title}\nPosition: ${position}\nFailed to update`)
					}
				} catch (error) {
					let errorMessage = error?.message || error?.toString() || 'Unknown error'
					let errorStack = error?.stack || 'No stack available'
					let errorDetails = JSON.stringify(error, null, 2) // Shows extra info if error is an object
	
					Alert.alert(
						'API Error',
						`Error updating track position\n\nTrack: ${trackItem.track.song_title}\nPosition: ${position}\n\n` +
						`Error Message: ${errorMessage}\n\nStack:\n${errorStack}\n\nDetails:\n${errorDetails}`
					)
				}
			}
		} catch (globalError) {
			let errorMessage = globalError?.message || globalError?.toString() || 'Unknown error'
			let errorStack = globalError?.stack || 'No stack available'
			let errorDetails = JSON.stringify(globalError, null, 2)
	
			Alert.alert(
				'Unexpected Error',
				`A critical error occurred\n\nError Message: ${errorMessage}\n\nStack:\n${errorStack}\n\nDetails:\n${errorDetails}`
			)
		}
	}

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	else
		return loading ? (
			<Loader />
		) : (
			<View style={{ backgroundColor: colors.primary, flex: 1 }}>
				<TrackList
					photo={photo}
					title={title}
					tracksList={trackListState}
					isTracksRemovable={isTracksRemovable}
					setTrackList={handleSetTrackList}
					userPlaylists={userPlaylists}
					isTrackPositionChangeable={isTrackPositionChangeable}
					navigation={navigation}
					updateUserPlaylists={updateUserPlaylists}
					openNotifModal={openNotifModal}
					closeNotifModal={closeNotifModal}
					edit={edit}
					setEdit={setEdit}
					tracks={trackListState.length}
					hasCopyPlaylistBtn={hasCopyPlaylistBtn}
					musicTherapistName={musicTherapistName}
					musicTherapistId={musicTherapistId}
					saveTracksPosition={saveTracksPosition}
					copyMyPlaylistLabel={copyMyPlaylistLabel}
					trackList={trackListState}
					isHidePlaylistDetails={false}
					description={description}
				/>
				{
					notifModal.visible && (
						<NotificationModal
							title={notifModal.title}
							description={notifModal.description}
							visible={notifModal.visible}
							closeNotifModal={closeNotifModal}
							isDisappearing={notifModal.isDisappearing}
						/>
					)
				}
			</View >
		)
}
