import { Modal, StyleSheet, Text, View, useWindowDimensions, ScrollView } from 'react-native'
import { SecondaryButton } from '../../Components/Buttons/Button'
import { colors } from '../../Helpers/variables'
import { useEffect, useState } from 'react'
import useSound from '../../Hooks/useSound'
import useAxios from '../../Hooks/useAxios'
import axios from 'axios'

export const LyricsModal = ({ modalVisible, closeLyricsModal }) => {
	const api = useAxios()
	const [loading, setLoading] = useState(false)

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const { currentPlayingTrack, mediaPlayerAcquisition, setMediaPlayerAcquisition } = useSound()
    const [lyrics, setLyrics] = useState('')

	useEffect(() => {
        getLyrics()
	}, [])

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: 20,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 30,
			paddingBottom: 45,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5,
			minWidth: isWideScreen ? 700 : isSmallScreen ? 240  : 360,
			maxWidth: isWideScreen ? 700 : isSmallScreen ? 240  : 360,
			height: '90%'
		},
		label: {
			marginBottom: 20,
			color: 'white',
			fontSize: 20,
			marginTop: 10
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})
	
    const getLyrics = async () => {
		setLoading(true)
        const title = currentPlayingTrack.song_title
        const artist = currentPlayingTrack.artist_name
        const apiKey = '261393e97769558f6f5a8417ea8a7bd1'


		try {
			const url = `https://api.lyrics.ovh/v1/${artist}/${title}`
			axios.get(url,
				{
					headers: { 'Content-Type': 'application/json' }
				})
			.then(response => {
				console.log('data', response.data)
				setLyrics(response.data.lyrics)
				setLoading(false)
			}).catch(err => {
				console.log(err)
				setLyrics('Sangtekster ikke fundet.')
				setLoading(false)
			})

			//// api option 2
			// const url = `https://api.musixmatch.com/ws/1.1/matcher.lyrics.get?q_artist=${artist}&q_track=${title}&apikey=${apiKey}`
			// const response = await axios.get(url);
			// setLoading(false)
			// const lyricsData = response?.data?.message?.body?.lyrics;
			// if (lyricsData && lyricsData.lyrics_body) {
			//   	setLyrics(lyricsData.lyrics_body);
			// } else {
			// 	setLyrics('Sangtekster ikke fundet.')
			// }
			//// api option 2

		} catch (error) {
			console.log(error)
			setLyrics('Sangtekster ikke fundet.')
			setLoading(false)
		}
    }

	return (
		<Modal
			animationType="slide"
			visible={modalVisible}
			transparent={true}
			onRequestClose={() => {
				closeLyricsModal()
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<Text style={{ marginBottom: 20, color: 'white', fontSize: isWideScreen ? 30 : 22 }}>{currentPlayingTrack.song_title}</Text>
					<ScrollView>
                        <Text style={{ marginBottom: 20, color: 'white', fontSize: isWideScreen ? 24 : 20 }}>{lyrics}</Text>
                    </ScrollView>
					<View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
                        <SecondaryButton
                            title={'Luk'}
                            onPress={() => {
                                closeLyricsModal()
                            }}
                            style={{ width: '50%' }}
							loading={loading}
                        />
					</View>
				</View>
			</View>
		</Modal>
	)
}
