import { useEffect } from 'react'
import { FlatList, Pressable, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import Card from './components/Card'
import { v4 as uuidv4 } from 'uuid'
import { StackActions } from '@react-navigation/native'

export default function SearchResultComposer({ composers, navigation, mostRelated }) {

	return (
		<View>
			{mostRelated && composers && (
				<View>
					{mostRelated.length === 0 && composers.length === 0 && <Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>}
				</View>
			)}
			{mostRelated && (
				<View>
					{mostRelated.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
								Mest beslægtet
							</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={mostRelated}
									renderItem={({ item, index }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'flex-start',
												height: 170,
												margin: 13
											}}
										>
											<Pressable onPress={() => navigation.dispatch(StackActions.push('ComposerTracks', { composer: item }))} key={item + uuidv4}>
												<Card image={''} title={item} />
											</Pressable>
										</View>
									)}
									keyExtractor={(item, index) => `mostRelated-${index.toString()}`}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					)}
				</View>
			)}

			{composers && (
				<View>
					{composers.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
								Mindre beslægtet
							</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={composers}
									renderItem={({ item, index }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'flex-start',
												height: 170,
												margin: 13
											}}
										>
											<Pressable onPress={() => navigation.dispatch(StackActions.push('ComposerTracks', { composer: item }))} key={item + uuidv4}>
												<Card image={''} title={item} />
											</Pressable>
										</View>
									)}
									keyExtractor={(item, index) => `composers-${index.toString()}`}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					)}
				</View>
			)}
		</View>
	)
}
