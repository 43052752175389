import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal, StyleSheet, useWindowDimensions, View } from 'react-native';
import { ProgressStep, ProgressSteps } from 'react-native-progress-steps';
import { SecondaryButton } from '../../Components/Buttons/Button';
import { colors, TRACKS_CHECKER_URL } from '../../Helpers/variables';
import useTokens from '../../Hooks/useTokens';
import Loader from '../Loader/Loader';
import { ImportPlaylistModal } from '../Modals/ImportPlaylistModal';
import { ImportPurchaseTracksModal } from '../Modals/ImportPurchaseTracksModal';
import { ImportTracksModal } from '../Modals/ImportTracksModal';
import { ImportHelpModal } from './ImportHelpModal';
import { stringSimilarity } from 'string-similarity-js'

export const ImportFromThirdPartyModal = ({
	modalVisible,
	cancelCallback,
}) => {

	const spotifyLabel = 'Spotify'
	const youtubeLabel = 'Youtube'
	const csvLabel = 'CSV File'
	const appleLabel = 'Apple Music'

	const buttonTextStyle = {
		color: colors.accent,
		fontWeight: 'bold'
	}

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700 && height > 1000)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(true)
	const { getTokens } = useTokens()


	const onSubmitStep = () => {
		cancelCallback()
	}

	const [notifModal, setNotifModal] = useState({
		visible: false,
		data: [],
		isDisappearing: false
	})

	const [importObject, setImportObject] = useState({
		isInitial: true,
		importOption: 0,
		sourcePlatform: spotifyLabel,
		destinationPlaylist: 0,
		sourcePlaylistNames: [],
		sourcePlaylistValue: '',
		playlistName: '',
		tracksResponse: [],
		tracksCount: 0,
		tracksRetrievedMessage: '',
		playlistRetrievedMessage: '',
		existingTracks: [],
		nonExistingTracks: [],
		username: '',
		selectedFile: ''
	})

	const progressStepsStyle = {
		activeStepIconBorderColor: colors.accent,
		activeLabelColor: colors.accent,
		activeStepNumColor: 'white',
		activeStepIconColor: colors.primary,
		completedStepIconColor: colors.accent,
		completedProgressBarColor: colors.accent,
		completedCheckColor: colors.primary,
		labelFontSize: isWideScreen ? 14 : 11
	}

	const [currentStep, setCurrentStep] = useState(0)
	const [existingTracks, setExistingTracks] = useState([])
	const [nonExistingTracks, setNonExistingTracks] = useState([])
	const [hasInvalidInfo, setHasInvalidInfo] = useState(true)
	const [infoMessages, setInfoMessages] = useState([])

	const handleResize = () => {
		setIsWideScreen(width > 700 && height > 1000)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '80%'
			}

			return '80%'
		} else if (isSmallScreen) {
			return '90%'
		} else {
			return '80%'
		}
	}

	const modalStyle = StyleSheet.create({
		centeredView: {
			flex: 1,
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: colors.secondary,
			borderRadius: 20,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5,
			width: getModalWidth(),
			height: isWideScreen ? '90%' : '90%',
			marginTop: 20
		},
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : 18,
			marginLeft: isWideScreen ? 20 : 0
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 14,
			marginRight: '10%',
		},
		buttonTextStyle: {
			color: colors.primary,
			fontWeight: 'bold'
		}
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			data: [],
			isDisappearing: false
		})
	}

	const openGeneralInfo = () => {
		setNotifModal({
			visible: true,
			data: infoMessages,
			isDisappearing: false
		})
	}

	const handleInfoMessage = (response) => {
		setInfoMessages(response)
	}

	const handleTracksResponse = async (updatedImportObject) => {
		if (!updatedImportObject.isInitial) {
			setImportObject(updatedImportObject)
			if (updatedImportObject.sourcePlatform === csvLabel) {
				await checkExistingTracksFromCSV(updatedImportObject.tracksResponse)
			} else {
				await checkExistingTracks(updatedImportObject.tracksResponse)
			}
		}
		// setCurrentStep(1) //uncomment this line if you want to redirect to next step after import playlist button
	}

	const handleRequestInfo = (status) => {
		setHasInvalidInfo(status)
	}

	const checkExistingTracks = async (tracksResponse) => {
		if (tracksResponse) {
			setLoading(true)
			const { access } = await getTokens()

			let dataArrayExisting = []
			let dataArrayNonExisting = []

			tracksResponse.items.map(async (item, index) => {
				// try {
				const title = item.track.name.trim()
				const album = item.track.album?.name
				const artists = item.track.artists
				let singer = ''
				if (artists.length > 1) {
					let counter = 1
					artists.map(artist => {
						singer += artist.name.trim()
						if (counter < artists.length) {
							singer += ','
						}
						counter++
					})
				} else {
					singer = artists ? artists[0].name.trim() : ''
				}


				let url = TRACKS_CHECKER_URL
				url = url.replace('{singer}', singer)
				url = url.replace('{title}', title)

				axios.get(url, {
					headers: { Authorization: `JWT ${access}` }
				}).then(async response => {
					if (response.status === 200) {
						let newDataArray = {
							id: index + 1,
							title: title,
							artists: singer,
							isChecked: response.data.isExist,
							trackId: response.data.trackId,
							album: album
						}

						if (response.data.isExist) {
							dataArrayExisting.push(newDataArray)
						} else {
							await checkNonExistingTracks(dataArrayNonExisting, newDataArray)
						}

						if (index === tracksResponse.items.length - 1) {
							setExistingTracks(dataArrayExisting)
							setNonExistingTracks(dataArrayNonExisting)
							setLoading(false)
						}
					} else {
						console.log('failed')
					}
				}).catch(err => {
					console.log(err)
				})
			})

		} else {
			alert('Ingen sange fundet')
			setLoading(false)
			return
		}
	}

	const checkExistingTracksFromCSV = async (tracksResponse) => {
		if (tracksResponse) {
			setLoading(true)
			const { access } = await getTokens()

			let dataArrayExisting = []
			let dataArrayNonExisting = []

			tracksResponse.map(async (item, index) => {
				const artists = item.Artist ? item.Artist : (item[0] ? '' + item[0] : '')
				const title = item.Track ? item.Track : (item[1] ? '' + item[1] : '')
				const album = item.Album ? item.Album : (item[2] ? '' + item[2] : '')

				let singer = ''
				if (artists.includes('|')) {
					singer = artists.trim().replace('|', ',')
				} else {
					singer = artists.trim()
				}

				let url = TRACKS_CHECKER_URL
				url = url.replace('{singer}', singer)
				url = url.replace('{title}', title.trim())
				axios.get(url, {
					headers: { Authorization: `JWT ${access}` }
				}).then(async response => {
					if (response.status === 200) {
						let newDataArray = {
							id: index + 1,
							title: title.trim(),
							artists: singer,
							isChecked: response.data.isExist,
							trackId: response.data.trackId,
							album: album
						}

						if (response.data.isExist) {
							dataArrayExisting.push(newDataArray)
						} else {
							await checkNonExistingTracks(dataArrayNonExisting, newDataArray)
						}

						if (index === tracksResponse.length - 1) {
							setExistingTracks(dataArrayExisting)
							setNonExistingTracks(dataArrayNonExisting)
							setLoading(false)
						}
					} else {
						console.log('failed')
					}
				}).catch(err => {
					console.log(err)
				})
			})

		} else {
			alert('Ingen sange fundet')
			setLoading(false)
			//error handling or empty handling here

			return
		}
	}

	const checkNonExistingTracks = async (dataArrayNonExisting, track) => {
		try {
			const query = encodeURIComponent(`${track.artists} ${track.title}`)
			axios.get(`https://itunes.apple.com/search?term=${query}&entity=song&limit=10`
			).then(async response => {
				if (!response.status === 200) {
					dataArrayNonExisting.push(track)
					console.error('Error fetching song data: ', response);
					return
				}
				
				const results = response?.data.results
				if (results.length > 0) {
					let previousRate = 0
					results.map(song => {
						const similarityRate = stringSimilarity(song.trackName, track.title)
						const isValidTitle = similarityRate >= 0.6 && similarityRate > previousRate
						if (isValidTitle) {
							previousRate = similarityRate
							track.title = song.trackName
							track.artists = song.artistName
						}
					})
				} else {
					console.log('No matching song found')
				}

				dataArrayNonExisting.push(track)
				return
			})
		} 
		catch (error) 
		{
			dataArrayNonExisting.push(track)
			console.error('Error fetching song data:', error)
			return
		}
	}

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				cancelCallback()
			}}
		>
			<View style={modalStyle.centeredView}>
				<View style={modalStyle.modalView}>
					<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', zIndex: 1, marginTop: 10 }} >
						<View>
							<SecondaryButton
								title='Generel information'
								onPress={() => openGeneralInfo()}
								style={{
									width: isWideScreen ? 150 : '80%',
									minHeight: 20,
									marginLeft: isWideScreen ? 20 : 10
								}}
							/>
						</View>
						<View>
							<SecondaryButton
								title='Annuller'
								onPress={() => cancelCallback()}
								style={{
									width: isWideScreen ? 120 : '90%',
									minHeight: 20,
									marginRight: isWideScreen ? 20 : 10
								}}
							/>
						</View>
					</View>
					<View style={{ flex: 1, marginTop: isWideScreen ? -30 : -20 }}>
						<ProgressSteps {...progressStepsStyle} activeStep={currentStep}>
							<ProgressStep
								label='Importer Spilleliste'
								nextBtnTextStyle={buttonTextStyle}
								nextBtnDisabled={importObject.tracksCount === 0}
								nextBtnText={'Næste'}
								loading={loading}
							>
								<ImportPlaylistModal
									importObject={importObject}
									handleSubmit={handleTracksResponse}
									infoMessage={handleInfoMessage} />
							</ProgressStep>
							<ProgressStep
								label='Importer Nummer'
								nextBtnTextStyle={buttonTextStyle}
								previousBtnTextStyle={buttonTextStyle}
								previousBtnText={'Forrige'}
								nextBtnText={'Næste'}
							>
								{
									loading ?
										<Loader bgColor={colors.secondary} /> :
										<ImportTracksModal
											importObject={importObject}
											existingTracks={existingTracks}
										/>
								}
							</ProgressStep>
							<ProgressStep
								label='Forespørg På Numre'
								nextBtnTextStyle={buttonTextStyle}
								finishBtnText={'Afslut'}
								previousBtnTextStyle={buttonTextStyle}
								previousBtnText={'Forrige'}
								onSubmit={onSubmitStep}
								nextBtnDisabled={hasInvalidInfo}
							>
								<ImportPurchaseTracksModal
									importObject={importObject}
									nonExistingTracks={nonExistingTracks}
									handleRequestInfo={handleRequestInfo}
								/>
							</ProgressStep>
						</ProgressSteps>
					</View>
				</View>
			</View>

			{notifModal.visible && (
				<ImportHelpModal modalVisible={notifModal.visible} setModalVisible={closeNotifModal} info={notifModal.data} />
			)}

		</Modal>
	)
}
