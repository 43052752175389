import { useEffect, useState } from 'react'
import { Platform, Modal, StyleSheet, Text, View, useWindowDimensions, FlatList, ScrollView, Pressable, Image } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../Buttons/Button'
import { colors } from '../../Helpers/variables'
import { NotificationModal } from './NotificationModal'

export const RequestedSongsDetails = ({
	songList,
	modalVisible,
	cancelCallback,
	isAdmin = false,
	tagAsDone = undefined,
	showNotifModal = false,
	setShowNotifModal = undefined,
	notifMessage = undefined
}) => {

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(false)
	const [updatedSongList, setUpdatedSongList] = useState(songList)
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	
	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const getModalWidth = () => {
		if (isWideScreen) {
			return '50%'
		} else {
			return '90%'
		}
	}

	const modalStyle = StyleSheet.create({
		centeredView: {
			flex: 1,
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: isAdmin ? colors.secondary : colors.primary,
			borderRadius: 20,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5,
			width: getModalWidth(),
			height: isWideScreen ? '80%' : '90%',
			marginTop: '5%'
		},
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : (isSmallScreen ? 12 : 18),
			marginLeft: isWideScreen ? 20 : 0
		},
		sublabel: {
			color: 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: '10%',
			marginBottom: 10
		},
		innerLabel: {
			color: 'white',
			padding: '2%',
			fontSize: isWideScreen ? 16 : (isSmallScreen ? 10 : 12),
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 12,
			marginLeft: '10%',
		},
		selectedIcon: {
			position: 'absolute',
			width: 30,
			height: 30,
			zIndex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 30,
			backgroundColor: colors.accent,
			borderRadius: 60
		},
		trackListScrollView: {
			// flexGrow: 1,
			borderColor: 'gray',
			borderRadius: 5,
			borderWidth: 2,
			width: '90%',
			// minHeight: '15%',
			marginTop: '2%',
			marginLeft: '5%',
			padding: 5,
			height: isWideScreen ? (height > 1000 ? 700 : 500) : (height < 680 ? 400 : 500),
		}
	})

	const getTranslatedStatus = (status) => {
		//add cases for other status below...
		let result = ''
		switch (status) {
			case "Pending":
				result = 'Afventer'
				break
			case "Completed":
				result = 'Fuldført'
				break
			default:
				result = status
				break
		}

		return result
	}

    const getAlbum = (album) => {
        if (!album || album === undefined || album === 'undefined') {
            return ''
        } else {
            return album.trim()
        }
    }

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}
	
	const handleTagging = (item) => {
		item.status = 1
		tagAsDone(item)
	}

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				cancelCallback()
			}}
		>
			<View style={modalStyle.centeredView}>
				<View style={modalStyle.modalView}>
					<View>
						<ScrollView style={modalStyle.trackListScrollView}>
							<View style={{
								flexDirection: 'row',
								alignItems: 'center',
								width: '100%',
								marginTop: 5,
								borderColor: 'gray',
								borderBottomWidth: 3
							}}>
								<View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: isWideScreen ? 30 : 0, width: '100%' }} >
									<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '50%' : '30%' }]}>Titel</Text>
									<Text style={[modalStyle.innerLabel, { width: '25%' }]}>Kunstner</Text>
									<Text style={[modalStyle.innerLabel, { width: '25%' }]}>Album</Text>
									<Text style={[modalStyle.innerLabel, { width: '25%' }]}>Status</Text>
									<Text style={[modalStyle.innerLabel, { width: '15%' }]}>{'   '}</Text>
								</View>
							</View>
							<View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5 }}>
								<FlatList
									data={updatedSongList}
									renderItem={song =>
										<View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: isWideScreen ? 30 : 0, width: '100%' }} >
											<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '50%' : '30%' }]}>{song.item.track}</Text>
											<Text style={[modalStyle.innerLabel, { width: '25%' }]}>{song.item.artist}</Text>
											<Text style={[modalStyle.innerLabel, { width: '25%' }]}>{getAlbum(song.item.album)}</Text>
											<Text style={[modalStyle.innerLabel, { width: '25%' }]}>{getTranslatedStatus(song.item.status_description)}</Text>
											{ isAdmin &&
												<PrimaryButton
													title='Tag as Done'
													onPress={() => handleTagging(song.item)}
													style={{ width: isWideScreen ? '15%' : (isSmallScreen ? '15%': '15%'), minHeight: isWideScreen ? 35 : 30, height: isWideScreen ? 35 : 30 }}
													isDisabled = {song.item.status !== 0}
												/>
											}
										</View>
									}
									ListEmptyComponent={
										<Text style={{ color: 'white' }}>Ingen data...</Text>
									}
									keyExtractor={song => song.id}
								/>
							</View>
						</ScrollView>
						<View style={{
							width: '90%',
							marginHorizontal: '5%',
							flexDirection: 'row',
							justifyContent: 'center',
							// marginTop: 10
						}}
						>
							<SecondaryButton
								title='Luk'
								onPress={() => cancelCallback()}
								style={{ backgroundColor: colors.secondary, width: '60%', minHeight: isWideScreen ? 20 : 15, justifyContent: 'flex-end' }}
							/>
						</View>
					</View>
				</View>
			</View>
			
			{showNotifModal && (
				<NotificationModal
					title={'Done'}
					description={notifMessage}
					visible={notifModal}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={setShowNotifModal}
					isDisappearing={false}
				/>
			)}

		</Modal>
	)
}
