import { Modal, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { SecondaryButton } from '../../Components/Buttons/Button'
import { colors } from '../../Helpers/variables'
import { useEffect, useState } from 'react'

export const NotificationModal = ({ title, description, visible, closeNotifModal, setModalVisibleExternal, isDisappearing }) => {

	useEffect(() => {
		if (isDisappearing) {
			const timeoutId = setTimeout(() => {
				closeNotifModal()
				if (setModalVisibleExternal) setModalVisibleExternal(false)
				clearTimeout(timeoutId)
			}, 2700)
		}
	}, [])

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			elevation: 5,
			zIndex: 999
		},
		modalView: {
			margin: 20,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 35,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5,
			zIndex: 999,
			width: isWideScreen ? '35%' : '70%',
			alignItems: 'center',
			justifyContent: 'center',
			height: '40%'
		}
	})
	

	return (
		<Modal
			animationType="slide"
			visible={visible}
			transparent={true}
			onRequestClose={() => {
				closeNotifModal()
				if (setModalVisibleExternal) setModalVisibleExternal(false)
			}}
		>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<Text style={{ marginBottom: 20, color: 'white', fontSize: isWideScreen ? 30 : 22 }}>{title}</Text>
					<Text style={{ marginBottom: 20, color: 'white', fontSize: isWideScreen ? 20 : 16 }}>{description}</Text>
					<View style={{ flexDirection: 'row', width: '100%'}}>
						{
							!isDisappearing &&
							<SecondaryButton
								title={'OK'}
								onPress={() => {
									closeNotifModal()
									if (setModalVisibleExternal) setModalVisibleExternal(false)
								}}
								style={{ width: '100%' }}
							/>
						}
					</View>
				</View>
			</View>
		</Modal>
	)
}
