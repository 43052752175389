/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { Platform, ScrollView, Text, useWindowDimensions, View } from 'react-native'

/* --------------------------- Component Imports ---------------------------- */
import Loader from '../../Components/Loader/Loader'
import CategoryCard from './components/CategoryCard'

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
import { addDataToContainersTable, retrieveDataFromContainersTable } from '../../Helpers/database/containersTable'
import { colors, MAIN_CONTAINER_URL } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import { FlatList } from 'react-native'
import { ResetButton } from '../../Components/Buttons/Button'
import RefreshButton from '../../Components/RefreshButton'
import { Heading } from '../../Components/Typography/Typography'
import { captureException } from '../../Helpers/sentryLog'
import { isCachedDataValid } from '../../Helpers/timeConverter'
import TopTracksScreen from '../TopChartsScreen/TopTracksScreen'
import WhatsNewScreen from '../WhatsNewScreen/WhatsNewScreen'

export default function HomeScreen({ navigation }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const api = useAxios()

	const { deviceType, isOffline } = useLayout()

	const [containers, setContainer] = useState([])
	const [originalContainers, setOriginalContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)
	const [shouldReset, setShouldReset] = useState(false)

	const playlistContainerLabel = 'Spilleliste container'
	const artistLabel = 'Kunstnere'
	const albumLabel = 'Albums'

	const [selectedCategory, setSelectedCategory] = useState(playlistContainerLabel)

	const getDataFromApi = async () => {
		// await new Promise(r => setTimeout(r, 1000))
		const { data } = await api.get(MAIN_CONTAINER_URL)
		// console.log(JSON.stringify(data))
		setContainer(data)
		setOriginalContainers(data)
		addDataToContainersTable(data)
	}

	const GridContainers = ({ items, label }) => {
		return <View style={{ flex: 1 }}>
			<View style={{ padding: isWideScreen ? 10 : 5, marginBottom: isWideScreen ? 10 : 5, width: '100%' }}>
				<Text style={{ color: 'lightgray', fontSize: isWideScreen ? 22 : (isSmallScreen ? 18 : 20), width: '99%' }}>{label}</Text>
			</View>
			<FlatList
				data={items}
				renderItem={({ item }) => (
					<CategoryCard
						wasDataCached={wasDataCached}
						item={item}
						navigate={navigation.navigate}
						deviceType={deviceType}
						newWidth={isSmallScreen ? 100 : (isWideScreen ? 130 : 120)}
					/>
				)}
				ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
				keyExtractor={item => item.id}
				//if the user pulls from the top of the screen, log out to the console:
				onRefresh={() => console.log('refreshing')}
				//if set to true, the UI will show a loading indicator and trigger data.
				refreshing={false}
				horizontal={true}
			/>
		</View>
	}

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)
			async function boot() {
				try {
					if (Platform.OS === 'web') {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				const cachedData = await retrieveDataFromContainersTable()
				if (!isCachedDataValid(cachedData)) {
					await getDataFromApi()
				} else {
					setWasDataCached(true)
					setContainer(cachedData)
					setOriginalContainers(cachedData)
				}
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				<View style={{
					padding: isWideScreen ? 10 : 5,
					marginBottom: isWideScreen ? 10 : 5,
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: 'row'
				}}
				>
					<Heading style={{ color: colors.accent, fontSize: isWideScreen ? 22 : (isSmallScreen ? 18 : 20) }}>Kategorier</Heading>
					<ResetButton style={{ width: 100 }} onPress={() => setShouldReset(true)} isWideScreen={isWideScreen} />
				</View>
				<View>
					{
						containers.filter(x => x.container_row === 0).length > 0 &&
						<GridContainers items={containers.filter(x => x.container_row === 0)} label={'Arousal inddelte spillelister'} />
					}
					{
						containers.filter(x => x.container_row === 1).length > 0 &&
						<GridContainers items={containers.filter(x => x.container_row === 1)} label={'Palliation, miljømusik, temaer og meget mere'} />
					}
					{
						containers.filter(x => x.container_row === 2).length > 0 &&
						<GridContainers items={containers.filter(x => x.container_row === 2)} label={'Kunstnere, hits, baggrundsmusik og naturlyde'} />
					}
				</View>
				<WhatsNewScreen deviceType={deviceType} type={'playlistcontainers'} navigation={navigation} />
				<TopTracksScreen />
			</ScrollView>
		</View>
	)
}
