import Slider from '@react-native-community/slider'
import React, { useEffect, useState } from 'react'
import { Text, useWindowDimensions, View } from 'react-native'
import { useProgress } from 'react-native-track-player'
import { colors } from '../../../../../Helpers/variables'
import usePlaybackStatus from '../../../../../Hooks/usePlaybackStatus'
import useSound from '../../../../../Hooks/useSound'
import useTimer from '../../../../../Hooks/useTimer'

const SeekControls = () => {
	const { width, height } = useWindowDimensions()
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 450)
	const { timerProperties, mediaPlayerTimerInterval } = useTimer()

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 450)
	}

	const {
		currentPlayingTrack,
		handleSeek,
		postRoyaltyLogging,
		setCurrentTrack,
		repeatType,
		nextTrack,
		queueTracksList,
		currentPlayingTrackIndex
	} = useSound()
	const { position, duration } = useProgress(1000) // Updates every second
	let displayDuration = Math.floor(duration / 60) + ':' + (duration % 60).toFixed(0).padStart(2, '0')
	const [displayPosition, setDisplayPosition] = useState('0:00')
	const { playbackStatus, setPlaybackStatus } = usePlaybackStatus()

	useEffect(() => {
		if (timerProperties.isTimerChecked) {
			mediaPlayerTimerInterval()
		}
		if (position === 11) postRoyaltyLogging(currentPlayingTrack.id)

		let newDisplayPosition = Math.floor(position / 60) + ':' + (position % 60).toFixed(0).padStart(2, '0')
		setDisplayPosition(newDisplayPosition)

		if (
			position === duration &&
			(repeatType === 'all' || repeatType === 'repeatAllTracks') &&
			position > 0 &&
			currentPlayingTrackIndex < queueTracksList.length - 1
		) {
			nextTrack()
		}
	}, [position])

	return (
		<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
			<Text
				style={{
					color: currentPlayingTrack === null ? colors.secondary : 'white',
					fontSize: 10
				}}
			>
				{displayPosition}
			</Text>
			<Slider
				style={{ marginHorizontal: 1, width: isSmallScreen ? 100 : 170, height: 40 }}
				minimumValue={0}
				maximumValue={duration}
				value={position}
				minimumTrackTintColor={colors.accent}
				thumbTintColor={currentPlayingTrack === null ? colors.secondary : colors.accent}
				maximumTrackTintColor={colors.secondary}
				step={1}
				thumbStyle={{ width: 15, height: 15 }}
				onSlidingComplete={value => {
					handleSeek(value)
				}}
			/>
			<Text
				style={{
					color: currentPlayingTrack === null ? colors.secondary : 'white',
					fontSize: 10
				}}
			>
				{displayDuration}
			</Text>
		</View>
	)
}

export default React.memo(SeekControls)
