import { Modal, Text, View, Alert, StyleSheet, Platform, useWindowDimensions } from 'react-native'
import { ASSIGNED_URL, colors, USERLIST_URL } from '../../Helpers/variables'
import { SecondaryButton } from '../Buttons/Button'
import { PrimaryButton } from '../Buttons/Button'
import useTokens from '../../Hooks/useTokens'
import { CheckListbox } from '../Forms/CheckListbox'
import { Listbox } from '../Forms/Listbox'
import { useState, useEffect } from 'react'
import axios from 'axios'
import * as Sentry from 'sentry-expo'
import useAxios from '../../Hooks/useAxios'

export const AssignUserToPlaylistModal = ({ isModalOpen, setModalOpen, playlistName, playlistId }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	const { getTokens } = useTokens()
	const [selectedItems, setSelectedItems] = useState([])
	const [originalSelectectedItems, setOriginalSelectedItems] = useState([])
	const [removedItems, setRemovedItems] = useState([])
	const [users, setUser] = useState([])
	const [isUserLoaded, setUserLoaded] = useState(false)
	const [isSelectAllChecked, setSelectAllChecked] = useState(false)
	const api = useAxios()

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			width: isWideScreen ? '40%' : '80%',
			height: isWideScreen ? '80%' : '80%',
			margin: isWideScreen ? 15 : 10,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
			elevation: 5
		},
		label: {
			marginBottom: 10,
			color: 'white',
			fontSize: isWideScreen ? 20 : 18,
			marginLeft: '3%',
			marginTop: '5%'
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})

	const getUser = () => {
		api
			.get(USERLIST_URL)
			.then(({ data }) => {
				// alert(JSON.stringify(data))
				data = data.sort(function (a, b) {
					return (a.user ? a.user : a.id) - (b.user ? b.user : b.id)
				})
				setUser(data)
				getUserAssignedPlaylist(data)
			})
			.catch(err => {
				console.log(err)
				if (Platform.OS === 'web') Sentry.Browser.captureException(err)
				else Sentry.Native.captureException(err)
			})
	}

	const getUserAssignedPlaylist = (usersTemp) => {
		api
			.get(ASSIGNED_URL + playlistId)
			.then(({ data }) => {
				// alert(JSON.stringify(data))
				data = data.sort(function (a, b) {
					return a.user - b.user
				})

				let listboxItemsTemp = usersTemp
				let selectedListItemsTemp = []
				// alert(JSON.stringify(listboxItemsTemp))
				data.map(item => {
					let itemTemp = listboxItemsTemp.filter(x => x.id === item.user)[0]
					listboxItemsTemp = listboxItemsTemp.filter(x => x.id !== item.user)
					if (itemTemp !== undefined) {
						itemTemp.isChecked = true
						itemTemp.assignedId = item.id
						listboxItemsTemp.push(itemTemp)
						selectedListItemsTemp.push(itemTemp)
					}
				})
				listboxItemsTemp = listboxItemsTemp.sort(function (a, b) {
					return (a.user ? a.user : a.id) - (b.user ? b.user : b.id)
				})
				selectedListItemsTemp = selectedListItemsTemp.sort(function (a, b) {
					return (a.user ? a.user : a.id) - (b.user ? b.user : b.id)
				})
				setUser(listboxItemsTemp)
				setSelectedItems(selectedListItemsTemp)
				setOriginalSelectedItems(selectedListItemsTemp)
			})
			.catch(err => {
				console.log(err)
				// if (Platform.OS === 'web') Sentry.Browser.captureException(err)
				// else Sentry.Native.captureException(err)
			})
	}

	const removeUserPlaylist = () => {
		let totalCount = 0
		removedItems.map(item => {
			api
				.delete(ASSIGNED_URL + item.assignedId)
				.then(response => {
					if (response.status === 204) {
						if (selectedItems.length === 0) totalCount++
						console.log('remove playlist success: ' + item.id)
						if (totalCount === removedItems.length && selectedItems.length === 0) {
							Alert.alert('Fjernet spillelisten', 'Spillelisten er blevet fjernet til brugeren', [
								{ text: 'OK', onPress: () => console.log('OK Pressed') }
							])

							setModalOpen(false)
						}
					} else {
						console.log('remove playlist fail: ' + item.id)
					}
				})
				.catch(err => {
					console.log(err)
				})
		})
	}

	const addUserPlaylist = () => {
		let totalCount = 0
		selectedItems.map(item => {
			handleAddUser(item)
			totalCount = totalCount + 1
			if (totalCount === selectedItems.length) {
				if (Platform.OS === 'web') {
					alert('Spillelisten er blevet tildelt til brugeren')
				}
				else {
					Alert.alert('Opret spillelisten', 'Spillelisten er blevet tildelt til brugeren', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}

				setModalOpen(false)
			}
			return true
		})
	}

	const handleAddUser = async item => {
		const { access } = await getTokens()
		let is_allowed_to_be_removed = false
		let playlist = playlistId
		let user = item.user ? item.user : item.id
		axios
			.post(
				ASSIGNED_URL,
				{
					is_allowed_to_be_removed,
					user,
					playlist
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					console.log('success')
				} else {
					console.log('failed')
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	useEffect(() => {
		getUser()
	}, [playlistId])

	// useEffect(() => {
	// 	getUserAssignedPlaylist()
	// }, [isUserLoaded])

	const refreshListboxSelectedItems = (item, isChecked) => {
		let selectedItemsTemp = selectedItems
		if (isChecked) {
			let selectedItem = selectedItemsTemp.filter(x => (x.user ? x.user : x.id) === (item.user ? item.user : item.id))
			if (selectedItem.length === 0) selectedItemsTemp.push(item)

			selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
				return (a.user ? a.user : a.id) - (b.user ? b.user : b.id)
			})
			setSelectedItems(selectedItemsTemp)
		} else {
			selectedItemsTemp = selectedItemsTemp.filter(x => (x.user ? x.user : x.id) !== (item.user ? item.user : item.id))
			setSelectedItems(selectedItemsTemp)

			let removedItem = originalSelectectedItems.filter(
				x => (x.user ? x.user : x.id) === (item.user ? item.user : item.id)
			)[0]
			let removedItemsTemp = removedItems
			removedItemsTemp.push(removedItem)
			setRemovedItems(removedItemsTemp)
		}
	}

	const handleRemoveAll = () => {
		let listboxItemsTemp = users
		listboxItemsTemp = listboxItemsTemp.filter(x => (x.isChecked = false))
		setSelectedItems([])
		let removedItemsTemp = []
		originalSelectectedItems.map(item => {
			let removedItem = originalSelectectedItems.filter(
				x => (x.user ? x.user : x.id) === (item.user ? item.user : item.id)
			)[0]
			removedItemsTemp.push(removedItem)
		})
		setRemovedItems(removedItemsTemp)
		setSelectAllChecked(false)
	}

	const handleOk = () => {
		if (selectedItems.length > 0) addUserPlaylist()

		if (removedItems.length > 0) removeUserPlaylist()
	}

	return (
		<View style={styles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={isModalOpen}
				onRequestClose={() => {
					handleClose()
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={{ flexDirection: 'row' }}>
							<Text style={[styles.label]}>{'Tildel brugeren til ' + playlistName}</Text>
						</View>
						<View style={{ height: '75%', flexDirection: 'row', justifyContent: 'center' }}>
							<CheckListbox
								listboxItems={users}
								setListboxItems={setUser}
								hasSelectAll={true}
								hasListboxSelectedItems={true}
								refreshListboxSelectedItems={refreshListboxSelectedItems}
								title={'Vælg brugere'}
								handleRemoveAll={handleRemoveAll}
								setSelectAllChecked={setSelectAllChecked}
								isSelectAllChecked={isSelectAllChecked}
								emptyLabel='Ingen brugere'
							/>
							<Listbox
								items={selectedItems}
								hasRemoveAll={true}
								title={'Udvalgte brugere'}
								handleRemoveAll={handleRemoveAll}
								emptyLabel='Ingen brugere'
							/>
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
							<SecondaryButton
								title={'Anuller'}
								onPress={() => setModalOpen(false)}
								style={{
									width: isWideScreen ? 180 : (isSmallScreen ? 90 : 120),
									minHeight: isWideScreen ? 35 : 35,
									margin: isWideScreen ? 10 : 5
								}}
							/>
							<PrimaryButton
								title={'Gem'}
								onPress={() => handleOk()}
								style={{
									width: isWideScreen ? 180 : (isSmallScreen ? 90 : 120),
									minHeight: isWideScreen ? 35 : 30,
									margin: isWideScreen ? 10 : 5
								}}
							/>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
