import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AppState, Platform } from 'react-native'
import TrackPlayer, { Event, RepeatMode, useTrackPlayerEvents } from 'react-native-track-player'
import * as Sentry from 'sentry-expo'
import { ROYALTY_LOGGING_URL } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'

export const SoundContext = React.createContext()

export const SoundProvider = ({ children }) => {
	const [oldTrackList, setOldTrackList] = useState([])
	const [trackList, setTrackList] = useState([])
	const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null)
	const [currentPlayingTrackIndex, setCurrentPlayingTrackIndex] = useState(0)
	const [isShuffleActive, setIsShuffleActive] = useState(false)
	const [repeatType, setRepeatType] = useState('none')
	const [repeatOnce, setRepeatOnce] = useState(false)
	const [volume, setVolume] = useState(100)
	const [queueTracksList, setQueueTracksList] = useState([])

	const [mediaPlayerAcquisition, setMediaPlayerAcquisition] = useState({
		isShuffleBtnVisible: true,
		isRepeatBtnVisible: true
	})

	const { getTokens } = useTokens()
	let [isRoyaltyLogSent, setIsRoyaltyLogSent] = useState(false)

	const handleAppStateChange = async nextAppState => {
		if (nextAppState === 'inactive') {
			await TrackPlayer.stop() // Stop the player when app is closing
		}
	}

	// Subscribe to AppState changes
	AppState.addEventListener('change', handleAppStateChange)

	const postRoyaltyLogging = async id => {
		console.log('postRoyaltyLogging')
		if (isRoyaltyLogSent) return

		isRoyaltyLogSent = true
		setIsRoyaltyLogSent(true)

		const { access } = await getTokens()
		axios
			.post(
				ROYALTY_LOGGING_URL + id,
				{},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) console.log('Success royalty logging...')
				else console.log('Failed royalty logging...')
			})
	}

	const addTracksToPlayer = async (tracksList, isReset) => {
		//to-do
		//1. force logic to remove next loaded song if unshuffling
		//2. on shuffle click, if currentplayingtrack is not null, force it to first item
		//and make sure all tracks are in shuffled list to prevent ending without playing all
		//3. fix issue on repeat
		console.log('sound-addTracksToPlayer')
		if (isReset) await TrackPlayer.reset()
		let newTracks = []
		tracksList.map(item => {
			let track_file = item.track?.track_file || item.track_file
			if (track_file) {
				let trackObject = getTrackObject(item.track || item)
				newTracks.push(trackObject)
			}
		})
		console.log('TrackPlayer newTacks', newTracks)
		await TrackPlayer.add(newTracks)
	}

	const getTrackObject = track => {
		console.log('getTrackObject')
		return {
			url: track.track_file,
			title: track.song_title,
			artist: track.artist_name,
			album: track.album_name,
			genre: track.genre,
			artwork: track.album_photo
		}
	}

	const addTrack = async (track, insertBeforeIndex = 0) => {
		console.log('addTrack')
		let trackObject = getTrackObject(track)
		await TrackPlayer.add(trackObject, insertBeforeIndex)
	}

	const addTracks = async (tracksList, isReset = true) => {
		console.log('sound-addTracks')
		setCurrentPlayingTrack(null)
		await addTracksToPlayer(tracksList, isReset).then(result => {
			setTrackList(tracksList)
			setQueueTracksList(tracksList)
		})
	}

	const loadTrack = async track => {
		console.log('loadTrack')
		let trackObject = getTrackObject(track)
		await TrackPlayer.load(trackObject)
	}

	const playTrack = async (track, trackIndex = currentPlayingTrackIndex, isLoadNewTrack = true) => {
		console.log('playTrack-trackIndex', trackIndex)
		console.log('playTrack-currentPlayingTrackIndex', currentPlayingTrackIndex)
		if (trackIndex !== currentPlayingTrackIndex || isLoadNewTrack) {
			console.log('skipping trackIndex ', trackIndex)
			await TrackPlayer.skip(trackIndex)
		}
		setCurrentPlayingTrackIndex(trackIndex)
		setCurrentPlayingTrack(track)
		await TrackPlayer.play()
		// let activeIndex = await TrackPlayer.getActiveTrackIndex()
		// console.log('activeIndex: ' + activeIndex)
	}

	const pauseTrack = async () => {
		console.log('pauseTrack')
		await TrackPlayer.pause()
	}

	const removeTrack = async trackIndex => {
		console.log('removeTrack')
		await TrackPlayer.remove(trackIndex)
	}

	const setCurrentTrack = async (activeIndex, type = 'next') => {
		if (type === 'next') {
			if (queueTracksList.length > 0) {
				// let activeIndex = currentPlayingTrackIndex //queueTracksList.findIndex(item => item.track.id === currentPlayingTrack.id)
				let newTrackIndex = currentPlayingTrackIndex
				console.log('setCurrentTrack ' + currentPlayingTrackIndex + ' ' + activeIndex)
				if (currentPlayingTrackIndex !== activeIndex) {
					console.log('currentPlayingTrackIndex !== activeIndex')
					newTrackIndex = newTrackIndex + 1
					if (newTrackIndex > queueTracksList.length - 1) {
						if (repeatType !== 'none' && repeatType !== 'one') {
							newTrackIndex = 0
						}
					}
					setCurrentPlayingTrackIndex(newTrackIndex)
					setCurrentPlayingTrack(queueTracksList[newTrackIndex]?.track || queueTracksList[newTrackIndex])
				}
			}
		}
	}

	const nextTrack = async () => {
		console.log('nextTrack')
		await TrackPlayer.skipToNext()
	}

	const previousTrack = async () => {
		console.log('previousTrack')
		let newTrackIndex = currentPlayingTrackIndex
		if (newTrackIndex > 0) newTrackIndex = newTrackIndex - 1
		else newTrackIndex = queueTracksList.length - 1
		setCurrentPlayingTrackIndex(newTrackIndex)
		setCurrentPlayingTrack(queueTracksList[newTrackIndex].track || queueTracksList[newTrackIndex])
		await TrackPlayer.skipToPrevious()
	}

	/*
	 * function handleSeek()
	 *
	 * @description: Handle track position change
	 * if the track is playing, update the position
	 * according to the passed in position value from the track player component
	 *
	 * @param position {number} - milliseconds
	 */
	const handleSeek = async position => {
		try {
			console.log('handleSeek')
			await TrackPlayer.seekTo(position)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	/*
	 * function handleClickShuffle()
	 *
	 * @description: Determines if shuffle or unshuffle of tracks list
	 */
	const handleClickShuffle = async () => {
		if (queueTracksList.length > 0) {
			console.log('handleClickShuffle')
			if (!isShuffleActive) {
				setIsShuffleActive(true)
				await shuffle()
			} else {
				setIsShuffleActive(false)
				let indexPlayingTrackFromOldTrackList = oldTrackList.findIndex(item => item.track.id === currentPlayingTrack.id)
				setCurrentPlayingTrackIndex(indexPlayingTrackFromOldTrackList)
				await addTracksToPlayer(oldTrackList).then(result => {
					setQueueTracksList([])
					setQueueTracksList(oldTrackList)
					setTrackList(oldTrackList)
					setOldTrackList([])
				})
			}
		}
	}

	/*
	 * function generateRandomIndex()
	 *
	 * @description: Generate random integer from 0 to total length of track list
	 */
	const generateRandomIndex = () => {
		let randomNum = Math.floor(Math.random() * queueTracksList.length + 1)
		randomNum = randomNum === queueTracksList.length ? randomNum - 1 : randomNum
		return randomNum
	}

	/*
	 * function shuffle()
	 *
	 * @description: Handles the shuffling of tracks list
	 */
	const shuffle = async () => {
		let trackListTemp = []
		do {
			let currentIndex = queueTracksList.length
			trackListTemp = []

			while (currentIndex !== 0) {
				let randomIndex = generateRandomIndex()
				if (currentIndex === 1) {
					// Find and move the currently playing track to the front
					let currentTrack = queueTracksList.find(x => x.id === queueTracksList[currentPlayingTrackIndex].id)
					trackListTemp = trackListTemp.filter(x => x.id !== queueTracksList[currentPlayingTrackIndex].id)
					trackListTemp.unshift(currentTrack)
					currentIndex -= 1
				} else {
					// Ensure no duplicates in the shuffled list
					if (!trackListTemp.some(x => x.id === queueTracksList[randomIndex].id)) {
						trackListTemp.push(queueTracksList[randomIndex])
						currentIndex -= 1
					}
				}
			}
		} while (JSON.stringify(trackListTemp) === JSON.stringify(queueTracksList))
		// Reshuffle if the order is unchanged

		if (trackListTemp.length > 0) {
			setOldTrackList(queueTracksList)
			await TrackPlayer.removeUpcomingTracks().then(async () => {
				await addTracksToPlayer(trackListTemp).then(() => {
					setQueueTracksList(trackListTemp)
					setTrackList(trackListTemp)
					setCurrentPlayingTrackIndex(0)
				})
			})
		}
	}

	/*
	 * function handleClickRepeat()
	 *
	 * @description: Handles the behavior of repeat type(repeat all, repeat one)
	 */
	const handleClickRepeat = async repeatType => {
		console.log('handleClickRepeat ' + repeatType)
		if (repeatType === 'none') {
			await TrackPlayer.setRepeatMode(RepeatMode.Off)
		} else if (repeatType === 'repeatAllTracks' || repeatType === 'all') {
			await TrackPlayer.setRepeatMode(RepeatMode.Queue)
		} else {
			await TrackPlayer.setRepeatMode(RepeatMode.Track)
		}
		setRepeatType(repeatType)
		setRepeatOnce(repeatType === 'one' ? true : false)
	}

	/*
	 * function handleChangeVolume()
	 *
	 * @description: Handles the volume change
	 */
	const handleChangeVolume = async volume => {
		console.log('handleChangeVolume')
		let newVolume = volume > 0 ? volume / 100 : volume
		try {
			await TrackPlayer.setVolume(newVolume)
			setVolume(volume)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	const clearQueue = async () => {
		console.log('clearQueue')
		await TrackPlayer.reset()
		setQueueTracksList([])
		setCurrentPlayingTrack(null)
		setCurrentPlayingTrackIndex(0)
	}

	useTrackPlayerEvents([Event.PlaybackActiveTrackChanged, Event.PlaybackQueueEnded], async event => {
		let activeIndex = await TrackPlayer.getActiveTrackIndex()
		if (event.type === Event.PlaybackQueueEnded || (activeIndex && activeIndex === queueTracksList.length)) {
			console.log('Playback queue ended. No more songs in the queue.')
			await TrackPlayer.pause()
			await TrackPlayer.seekTo(0)
			setCurrentPlayingTrack(null)
		} else if (event.type === Event.PlaybackActiveTrackChanged && currentPlayingTrack) {
			console.log('Event.PlaybackActiveTrackChanged')
			setCurrentTrack(activeIndex)
		}
	})

	return (
		<SoundContext.Provider
			value={{
				trackList,
				setTrackList,
				currentPlayingTrack,
				setCurrentPlayingTrack,
				isShuffleActive,
				repeatType,
				mediaPlayerAcquisition,
				setMediaPlayerAcquisition,
				volume,
				currentPlayingTrackIndex,
				setIsShuffleActive,
				setCurrentPlayingTrackIndex,
				addTrack,
				loadTrack,
				addTracks,
				playTrack,
				pauseTrack,
				removeTrack,
				nextTrack,
				previousTrack,
				handleSeek,
				handleClickShuffle,
				handleClickRepeat,
				handleChangeVolume,
				postRoyaltyLogging,
				setCurrentTrack,
				clearQueue,
				addTracksToPlayer,
				setQueueTracksList,
				setIsShuffleActive,
				queueTracksList
			}}
		>
			{children}
		</SoundContext.Provider>
	)
}
