import { Ionicons } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'

import { Alert, Platform, ScrollView, View, Text } from 'react-native'
import * as Sentry from 'sentry-expo'
import Loader from '../../Components/Loader/Loader'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import { ALBUM_TRACKS_URL, USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import axios from 'axios'
import useTokens from '../../Hooks/useTokens'
import PlaylistDetails from '../TracksScreen/components/PlaylistDetails'
import TrackList from '../TracksScreen/components/TrackList'
import { Menu, MenuItem } from 'react-native-material-menu'
import { CreatePlaylistModal } from '../TracksScreen/components/CreatePlaylistModal'
import { trackStyles } from '../TracksScreen/components/TrackStyles'
import { PLAYLIST_TRACKS_URL } from '../../Helpers/variables'

export default function AlbumTracksScreen({ route, navigation }) {
	const [albumDetails, setAlbumDetails] = useState([])
	const { albumId } = route.params ? route.params : global.routeParams
	const [loading, setLoading] = useState(true)
	const api = useAxios()
	const { getTokens } = useTokens()

	const [userPlaylists, setUserPlaylists] = useState([])
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [modalVisible, setModalVisible] = useState(false)
	const [menuVisible, setMenuVisible] = useState(false)
	const [playlistVisible, setPlaylistVisible] = useState(false)

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		async function boot() {
			let tracksList = []

			await api
				.get(USER_PLAYLIST_URL)
				.then(({ data }) => {
					data = playlistSortByName(data)
					setUserPlaylists(data)
				})
				.catch(error => {
					console.log(error)
					if (Platform.OS === 'web') Sentry.Browser.captureException(error)
					else Sentry.Native.captureException(error)
				})

			const { data } = await api.get(ALBUM_TRACKS_URL + albumId)
			if (data.length > 0) {
				data.map(itemMain => {
					itemMain.mm_tracks.map(item => {
						tracksList.push({
							track: item,
							Photo: itemMain.Photo,
							title: itemMain.album,
							id: item.id
						})
					})
				})
			}
			setAlbumDetails(tracksList)
			setLoading(false)
		}
		boot()
	}, [albumId])

	const handleModalClosing = (value) => {
		setModalVisible(value)
		setPlaylistVisible(value)
		setMenuVisible(value)
	}

	const handlePlaylistMenuClosing = () => {
		if (!modalVisible) {
			setPlaylistVisible(false)
		}
	}

	const addToPlaylist = async (playlistId, albumDetails) => {
		const { access } = await getTokens()
		albumDetails.map(item => {
			const track = item.track.id
			const position = 0

			axios
				.post(
					PLAYLIST_TRACKS_URL + playlistId,
					{ track, position },
					{
						headers: { Authorization: `JWT ${access}` }
					}
				)
				.then(response => {
					if (response.status === 201) {
						openNotifModal('Tilføj til spilleliste', 'Tilføjet', true)
					} else {
						openNotifModal('Tilføj til spilleliste', 'Kunne ikke tilføje', true)
					}
				})
				.catch(err => {
					console.log('addToPlaylist: ' + err)
					if (err.message === 'Request failed with status code 400') {
						openNotifModal('Tilføj til spilleliste', 'Nummeret findes allerede i spilleliste', true)
					}
				})
		})
	}

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				{albumDetails.length > 0 ? (
					<View>
						<View style={{ width: '100%', marginTop: 20, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
							<View>
								<PlaylistDetails
									photo={albumDetails[0].Photo}
									title={albumDetails[0].title}
									tracks={albumDetails.length}
									tracksList={albumDetails}
									isRepeatAllTracks={true}
									isHideTherapist={true}
									artistName={albumDetails[0].track.artist_name}
									description={albumDetails[0].description}
									albumId={albumId}
									hasCopyPlaylistBtn={true}
								/>
							</View>
							<View style={{ width: '20%', height: '100%', alignItems: 'flex-end' }}>
								<Menu
									style={trackStyles.menuView}
									key={'menu' + albumDetails[0].id}
									visible={menuVisible}
									anchor={
										<Ionicons
											key={'icon' + albumDetails[0].id}
											id={'icon' + albumDetails[0].id}
											name="ellipsis-vertical"
											size={25}
											style={{ marginLeft: 20 }}
											color={'white'}
											onPress={() => {
												setMenuVisible(true)
											}}
										/>
									}
									onRequestClose={() => setMenuVisible(false)}
								>
									<View>
										<Menu
											style={trackStyles.menuView}
											key={'playlistmenu' + albumDetails[0].id}
											visible={playlistVisible}
											anchor={
												<MenuItem
													onPress={() => {
														setPlaylistVisible(true)
													}}
												>
													<Text style={trackStyles.menuTextView}>Tilføj til spilleliste</Text>
												</MenuItem>
											}
											onRequestClose={() => handlePlaylistMenuClosing() }
										>
											<View>
												<Ionicons name="arrow-back" size={25} color={'white'} style={{ marginLeft: 15 }} />
											</View>
											<MenuItem
												key={'playlistkey00'}
												onPress={() => {
													setModalVisible(true)
												}}
												onRequestClose={() => handlePlaylistMenuClosing() }
											>
												<Text style={[trackStyles.menuTextView, { margin: 15, fontSize: 15, fontWeight: 'bold' }]}>
													Opret ny spilleliste
												</Text>
											</MenuItem>
											<ScrollView style={{ maxHeight: 200 }}>
												{userPlaylists.map(playlist => (
													<MenuItem
														key={'playlistkey' + playlist.id}
														onPress={() => {
															addToPlaylist(playlist.id, albumDetails)
															setPlaylistVisible(false)
															setMenuVisible(false)
														}}
													>
														<Text style={trackStyles.menuTextView}>{playlist.name}</Text>
													</MenuItem>
												))}
											</ScrollView>
												{modalVisible && (
													<CreatePlaylistModal
														modalVisible={modalVisible}
														setModalVisible={handleModalClosing}
														updateUserPlaylists={updateUserPlaylists}
														addToPlaylist={addToPlaylist}
														albumDetails={albumDetails}
													/>
												)}
										</Menu>
									</View>
								</Menu>
							</View>
						</View>
						<TrackList
							photo={albumDetails[0].Photo}
							title={albumDetails[0].title}
							tracksList={albumDetails}
							key={albumDetails[0].id}
							navigation={navigation}
							userPlaylists={userPlaylists}
							updateUserPlaylists={updateUserPlaylists}
							openNotifModal={openNotifModal}
							closeNotifModal={closeNotifModal}
							isRepeatAllTracks={true}
							description={albumDetails[0].description}
						/>
					</View>
				) : (
					<View>
						<Text>Ingen data</Text>
					</View>
				)}

				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</ScrollView>
		</View>
	)
}
