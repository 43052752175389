import { Ionicons } from '@expo/vector-icons'
import React, { useState } from 'react'
import { Text, View, Pressable } from 'react-native'
import { Menu } from 'react-native-material-menu'
import truncateStr from '../../../Helpers/truncateStr'
import { AddQueueTrack } from './AddQueueTrack'
import { PlaylistTrackMenu } from './PlaylistTrackMenu'
import { RemoveTrackPlaylist } from './RemoveTrackPlaylist'
import { MoveTrackPlaylist } from './MoveTrackPlaylist'
import { trackStyles } from './TrackStyles'
import { useNavigation, StackActions } from '@react-navigation/native'

export const TrackMenu = ({
	track,
	playlistId = undefined,
	playlistTrackId,
	isTracksRemovable,
	setTrackList,
	tracksList,
	userPlaylists,
	updateUserPlaylists,
	openNotifModal,
	closeNotifModal,
	item
}) => {
	const [menuVisible, setMenuVisible] = useState(false)
	const navigation = useNavigation()

	return (
		<View key={'view' + track.id}>
			<Menu
				style={trackStyles.menuView}
				key={'menu' + track.id}
				visible={menuVisible}
				anchor={
					<Ionicons
						key={'icon' + track.id}
						id={'icon' + track.id}
						name="ellipsis-vertical"
						size={25}
						style={{ marginLeft: 20 }}
						color={'white'}
						onPress={() => {
							setMenuVisible(true)
						}}
					/>
				}
				onRequestClose={() => setMenuVisible(false)}
			>
				<View style={{ flexDirection: 'row', height: 'auto' }}>
					<Ionicons name="musical-notes" size={40} color={'white'} style={{ marginTop: 7 }} />
					<View style={{ marginTop: 5, marginLeft: 5, width: '100%' }}>
						<Text style={{ color: 'white', fontSize: 14, width: 150 }}>{truncateStr(track.song_title, 30)}</Text>
						<Text style={[trackStyles.albumTitle, { width: 150 }]}>{truncateStr(track.artist_name, 30)}</Text>
					</View>
				</View>
				<View style={{ marginTop: 10 }}>
					<PlaylistTrackMenu
						key={'playlisttrackview' + track.id}
						track={track}
						menuVisible={menuVisible}
						setMenuVisible={setMenuVisible}
						userPlaylists={userPlaylists}
						updateUserPlaylists={updateUserPlaylists}
						openNotifModal={openNotifModal}
						closeNotifModal={closeNotifModal}
						position={tracksList ? tracksList.length : 0}
					/>
					{playlistId && isTracksRemovable && (
						<MoveTrackPlaylist
							key={'moveTrackPlaylist' + track.id}
							track={track}
							setMenuVisible={setMenuVisible}
							userPlaylists={userPlaylists}
							openNotifModal={openNotifModal}
							position={tracksList ? tracksList.length : 0}
							tracksList={tracksList}
							setTrackList={setTrackList}
							playlistTrackId={playlistTrackId}
							playlistId={playlistId}
						/>
					)}
					{isTracksRemovable && (
						<RemoveTrackPlaylist
							playlistTrackId={playlistTrackId}
							tracksList={tracksList}
							setTrackList={setTrackList}
							openNotifModal={openNotifModal}
							closeNotifModal={closeNotifModal}
						/>
					)}
					<AddQueueTrack
						item={item}
						setMenuVisible={setMenuVisible}
						openNotifModal={openNotifModal}
						closeNotifModal={closeNotifModal}
					/>
					<Pressable onPress={() => {
							setMenuVisible(false)
							navigation.dispatch(StackActions.push('ArtistTracks', {
								artistId: track.artist,
								photo: track.album_photo,
								title: track.artist_name,
								description: track.artist_description
							}))
						}}>
							<Text style={{
								color: 'white',
								fontSize: 14,
								padding: 10,
								marginLeft: 5
							}}>
								Mere fra denne kunstner
							</Text>
						</Pressable>
						<Pressable onPress={() => {
							setMenuVisible(false)
							navigation.dispatch(StackActions.push('AlbumTracks', {
								albumId: track.album,
								photo: track.album_photo,
								title: track.album_name,
								description: track.album_description
							}))
						}}>
							<Text style={{
								color: 'white',
								fontSize: 14,
								padding: 10,
								marginLeft: 5
							}}>
								Mere fra dette album
							</Text>
						</Pressable>
				</View>
			</Menu>
		</View>
	)
}
