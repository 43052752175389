import React from 'react'
import { Text, View } from 'react-native'
import { MenuItem } from 'react-native-material-menu'
import useSound from '../../../Hooks/useSound'
import { trackStyles } from './TrackStyles'

export const AddQueueTrack = ({ item, setMenuVisible, openNotifModal, closeNotifModal }) => {
	const { trackList, setTrackList, currentPlayingTrack, currentPlayingTrackIndex, addTracksToPlayer } = useSound()
	const queueTrackLabel = 'Tilføj til kø'

	const addTrack = (tempList, currentTrack, generateNewIdTag = false) => {
		let newId = generateNewIdTag ? Math.floor(Math.random() * 10000000) : currentTrack.id
		let checkIfExist = tempList.filter(x => x.id === newId)
		let itemTemp = undefined
		if (checkIfExist.length > 0) {
			itemTemp = {
				id: newId,
				position: 0,
				track: currentTrack.track ? currentTrack.track : currentTrack
			}
			tempList.push(itemTemp)
		} else {
			currentTrack.track
				? tempList.push(currentTrack)
				: tempList.push({
						id: newId,
						position: 0,
						track: currentTrack
				  })
		}

		return tempList
	}

	const addQueueTrackList = () => {
		let tempList = []
		trackList.map((itemTrack, index) => {
			tempList = addTrack(tempList, itemTrack)
			if (currentPlayingTrackIndex === index) {
				tempList = addTrack(tempList, item.track ? item.track : item.item, item.track ? false : true)
				openNotifModal('Tilføj til kø', 'Tilføjet', true)
			}
		})
		addTracksToPlayer(tempList)
		setTrackList(tempList)
		setMenuVisible(false)
	}

	return (
		currentPlayingTrack && (
			<View>
				<MenuItem
					style={trackStyles.menuTextView}
					onPress={() => {
						addQueueTrackList()
					}}
				>
					<Text style={trackStyles.menuTextView}>{queueTrackLabel}</Text>
				</MenuItem>
			</View>
		)
	)
}
